:root {
  --color-primary: #006BAD;
  --color-primary-80: #006BADCC;
  --color-primary-60: #006BAD99;
  --color-primary-40: #006BAD66;
  --color-primary-20: #006BAD33;
  --color-primary-10: #006BAD1A;
  --color-primary-dark: #003C61;
  --color-primary-dark-80: #003C61CC;
  --color-primary-dark-60: #003C6199;
  --color-primary-dark-40: #003C6166;
  --color-primary-dark-20: #003C6133;
  --color-primary-dark-10: #003C611A;
  --color-primary-light: #45A8E6;
  --color-primary-light-80: #45A8E6CC;
  --color-primary-light-60: #45A8E699;
  --color-primary-light-40: #45A8E666;
  --color-primary-light-20: #45A8E633;
  --color-primary-light-10: #45A8E61A;
  --color-primary-main: #3F51B5;
  --color-secondary: #6501A8;
  --color-secondary-80: #6501A8CC;
  --color-secondary-60: #6501A899;
  --color-secondary-40: #6501A866;
  --color-secondary-20: #6501A833;
  --color-secondary-10: #6501A81A;
  --color-secondary-light: #A647E6;
  --color-secondary-light-80: #A647E6CC;
  --color-secondary-light-60: #A647E699;
  --color-secondary-light-40: #A647E666;
  --color-secondary-light-20: #A647E633;
  --color-secondary-light-10: #A647E61A;
  --color-secondary-gradient: linear-gradient(135deg, #45A8E6 22.4%, #A647E6 78.13%);;
  --color-secondary-gradient-80: linear-gradient(135deg, rgba(69, 168, 230, 0.8) 22.4%, rgba(166, 71, 230, 0.8) 78.13%);
  --color-secondary-gradient-60: linear-gradient(135deg, rgba(69, 168, 230, 0.6) 22.4%, rgba(166, 71, 230, 0.6) 78.13%);
  --color-secondary-gradient-40: linear-gradient(135deg, rgba(69, 168, 230, 0.4) 22.4%, rgba(166, 71, 230, 0.4) 78.13%);
  --color-secondary-gradient-20: linear-gradient(135deg, rgba(69, 168, 230, 0.2) 22.4%, rgba(166, 71, 230, 0.2) 78.13%);
  --color-secondary-gradient-10: linear-gradient(135deg, rgba(69, 168, 230, 0.1) 22.4%, rgba(166, 71, 230, 0.1) 78.13%);
  --color-text-primary: #000000de;
  --color-text-secondary: #00000099;
  --color-text-disabled: #00000061;
}

@font-face {
  font-family: "Futura Std";
  src: url(./fonts/FuturaStd-Bold.otf);
  font-weight: bold;
}
@font-face {
  font-family: "Futura Std";
  src: url(./fonts/FuturaStd-Medium.otf);
  font-weight: 500;
}
@font-face {
  font-family: "Futura Std";
  src: url(./fonts/FuturaStd-Light.otf);
  font-weight: 400;
}
